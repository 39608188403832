$platinum-color: #e9e6e1;
$chamoisee-color: #9e7758;
$davysgrey-color: #565657;

$secondary-color: #fea3a8;

$font-galanogrotesque-normal: "GalanoGrotesque", sans-serif;
$font-galanogrotesque-bold: "GalanoGrotesqueBold", sans-serif;
$font-galanogrotesque-medium: "GalanoGrotesqueMedium", sans-serif;

$font-cormorant: "Cormorant", serif;
