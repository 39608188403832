@font-face {
  font-family: "GalanoGrotesque";
  src: url("../fonts/galanogrotesque/GalanoGrotesque.woff") format("woff");
}

@font-face {
  font-family: "GalanoGrotesqueMedium";
  src: url("../fonts/galanogrotesque/GalanoGrotesqueMedium.woff") format("woff");
}

@font-face {
  font-family: "GalanoGrotesqueBold";
  src: url("../fonts/galanogrotesque/GalanoGrotesqueBold.woff") format("woff");
}

@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
