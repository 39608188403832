@import "styles/variables.scss";

.section-title {
  font-family: $font-galanogrotesque-bold;
  font-size: 3.75rem;

  @media (max-width: theme("screens.lg")) {
    font-size: 2.75rem;
  }

  &.chamoisee {
    color: $chamoisee-color;
  }
  &.davysgrey {
    color: $davysgrey-color;
  }
}
