@import "styles/variables.scss";

.we-do-card {
  background-color: #d9d9d9;
  border-radius: 47px;
  padding: 50px;

  display: flex;
  justify-content: space-between;
  gap: 50px;

  @media (max-width: theme("screens.lg")) {
    padding: 25px;

    flex-direction: column;
    gap: 0px;
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 35px;

    @media (max-width: theme("screens.lg")) {
      margin-top: 25px;
    }

    &__item {
      display: flex;
      align-items: flex-start;
      gap: 10px;

      &__icon {
        width: 25px;
        height: 25px;
        margin-top: 10px;

        @media (max-width: theme("screens.lg")) {
          margin-top: 6px;
        }

        svg {
          width: 25px;
          height: 25px;
        }
      }

      span {
        font-family: $font-galanogrotesque-medium;
        font-size: 1.5rem;
        color: $chamoisee-color;

        @media (max-width: theme("screens.lg")) {
          font-size: 1.25rem;
        }

        @media (max-width: theme("screens.sm")) {
          margin-top: 5px;
        }
      }
    }
  }
}
