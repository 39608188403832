@import "styles/variables.scss";

.btn-default {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;

  background-color: $platinum-color;
  padding: 15px 35px;

  border-radius: 55.5px;

  @media (max-width: theme("screens.sm")) {
    height: 60px;
    gap: 10px;
  }

  svg {
    width: 60px;
    height: 60px;

    @media (max-width: theme("screens.lg")) {
      width: 30px;
      height: 30px;
    }
  }

  span {
    font-family: $font-galanogrotesque-bold;
    font-size: 2.25rem;
    color: $chamoisee-color;
    white-space: nowrap;

    margin-top: -15px;

    @media (max-width: theme("screens.lg")) {
      font-size: 1rem;

      margin-top: -5px;
    }
    @media (max-width: theme("screens.sm")) {
      margin-top: 0px;
    }
  }
}
