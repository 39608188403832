@import "styles/variables.scss";

.lp {
  overflow-x: hidden;

  &__header {
    height: 1000px;
    // height: 800px;
    width: 100%;
    background-image: url("/images/header.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    position: relative;

    transition: background-position 0.25s ease-in-out,
      height 100000s ease-in-out;

    @media (max-width: theme("screens.lg")) {
      background-position: 80%;
      height: 800px;
    }

    &__logo-no-bordered {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: -63px;
      position: absolute;
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      gap: 35px;
      height: 100%;
      padding-top: 50px;
      width: 70%;

      @media (max-width: theme("screens.lg")) {
        padding-top: 0;
        padding-bottom: 75px;
        gap: 5px;
        justify-content: flex-end;
      }

      button {
        margin-top: 1rem;

        @media (max-width: theme("screens.sm")) {
          margin-top: 10px;
        }
      }

      &__txt {
        max-width: 920px;

        @media (max-width: theme("screens.lg")) {
          max-width: 100%;
        }
      }

      &__logo {
        svg {
          width: 500px;

          @media (max-width: theme("screens.sm")) {
            width: 100%;
          }
        }
      }

      h1 {
        font-family: $font-galanogrotesque-bold;
        font-size: 3.75rem;
        color: $platinum-color;

        @media (max-width: theme("screens.lg")) {
          font-size: 2.75rem;
        }

        @media (max-width: theme("screens.sm")) {
          font-size: 1.75rem;
        }
      }
      p {
        font-family: $font-cormorant;
        font-weight: 400;
        font-size: 1.75rem;
        color: $platinum-color;

        padding-top: 10px;

        @media (max-width: theme("screens.lg")) {
          font-size: 1.25rem;
        }
      }
    }
  }

  &__about-us {
    background-color: $platinum-color;
    width: 100%;
    height: 100%;
    padding-bottom: 900px;

    @media (max-width: theme("screens.lg")) {
      padding-bottom: 700px;
    }

    &__content {
      padding: 65px 0;

      @media (max-width: theme("screens.lg")) {
        padding: 70px 0;
      }

      h3 {
        text-align: center;
      }

      &__row {
        display: flex;
        justify-content: space-between;
        gap: 59px;
        padding-top: 50px;

        @media (max-width: theme("screens.lg")) {
          flex-direction: column;
          gap: 30px;
        }

        &__col {
          width: 50%;

          @media (max-width: theme("screens.lg")) {
            width: 100%;
          }

          p {
            padding-top: 15px;
            font-family: $font-cormorant;
            font-weight: 400;
            font-size: 1.75rem;
            color: $davysgrey-color;

            @media (max-width: theme("screens.lg")) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  &__what-we-do {
    width: 100%;
    height: 100%;

    &__content {
      padding-bottom: 100px;
      margin-top: -900px;

      @media (max-width: theme("screens.lg")) {
        margin-top: -700px;
      }

      h3 {
        text-align: center;
      }

      &__row {
        padding-top: 100px;
      }
    }
  }

  &__partners {
    display: flex;
    width: 100%;
    height: 550px;

    @media (max-width: theme("screens.lg")) {
      width: 100%;
    }

    @media (max-width: theme("screens.lg")) {
      flex-flow: column nowrap;
    }
    img {
      object-fit: cover;
      width: 50%;

      @media (max-width: theme("screens.lg")) {
        width: 100%;
        height: 250px;
      }
    }
    &__right {
      width: 50%;
      background: #575756;
      @media (max-width: theme("screens.lg")) {
        width: 100%;
      }

      &__text {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        gap: 35px;
        padding: 60px;

        @media (max-width: theme("screens.lg")) {
          padding: 55px 25px;
        }

        span {
          font-family: $font-galanogrotesque-bold;
          font-size: 2.75rem;
          color: $chamoisee-color;

          @media (max-width: theme("screens.lg")) {
            font-size: 2rem;
          }
        }

        ul {
          display: flex;
          flex-flow: column nowrap;

          li {
            font-family: $font-cormorant;
            font-weight: 400;
            font-size: 1.75rem;
            color: $platinum-color;

            @media (max-width: theme("screens.lg")) {
              font-size: 1.25rem;
            }
          }
        }
      }
    }
  }

  &__customers-say {
    width: 100%;
    height: 100%;

    margin-top: 75px;

    @media (max-width: theme("screens.lg")) {
      margin-top: 150px;
    }

    @media (max-width: theme("screens.sm")) {
      margin-top: 200px;
    }

    &__content {
      padding-bottom: 100px;

      h3 {
        text-align: center;
      }

      &__row {
        padding-top: 100px;

        display: flex;
        justify-content: center;
        gap: 100px;

        @media (max-width: theme("screens.lg")) {
          flex-direction: column;
          gap: 50px;
        }

        &__text-card {
          display: flex;
          flex-flow: column nowrap;
          gap: 15px;
          position: relative;
          width: 40%;

          @media (max-width: theme("screens.lg")) {
            width: 100%;
          }

          svg {
            position: absolute;
            right: 50px;
            top: 20px;
            z-index: 1;
          }

          span {
            z-index: 2;
            font-family: $font-galanogrotesque-bold;
            font-size: 1.75rem;
            color: $chamoisee-color;
            text-align: left;
          }
          p {
            z-index: 2;
            font-family: $font-cormorant;
            font-weight: 400;
            font-size: 1.5rem;
            color: $davysgrey-color;
          }
        }
      }
    }
  }

  &__contact-us {
    width: 100%;
    height: 575px;
    background-image: url("/images/contact-us.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      height: 100%;

      @media (max-width: theme("screens.lg")) {
        align-items: center;
      }

      span {
        font-family: $font-galanogrotesque-bold;
        font-size: 3.75rem;
        color: $chamoisee-color;
        text-align: right;

        @media (max-width: theme("screens.lg")) {
          font-size: 2rem;
          text-align: center;
        }
      }
    }
  }

  &__questions {
    width: 100%;
    height: 100%;

    margin-top: 75px;

    &__content {
      h3 {
        text-align: center;
      }

      &__item {
        border-top: 1px solid #b5b5b5;
        padding: 20px 40px 20px 0px;

        display: flex;
        flex-direction: column;

        &__title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          svg {
            width: 15px;
            height: 15px;
          }

          span {
            font-family: $font-cormorant;
            font-weight: 600;
            font-size: 1.75rem;
            color: $chamoisee-color;
            text-align: left;
          }
        }

        &__content {
          margin-top: 10px;
          max-height: 0;
          transition: max-height 0.15s ease-out;
          overflow: hidden;

          &.show {
            max-height: 1000px;
            transition: max-height 0.25s ease-in;
          }

          p {
            font-family: $font-cormorant;
            font-weight: 600;
            font-size: 1.25rem;
            color: #575756;
            text-align: left;
          }
        }
      }
    }
  }

  &__footer {
    margin-top: 150px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: theme("screens.xl")) {
      flex-direction: column;
      margin-top: 100px;
    }

    &__map {
      width: 50%;

      @media (max-width: theme("screens.xl")) {
        width: 100%;
      }
    }

    &__card {
      padding: 50px;
      background-color: #d9d9d9;
      margin-left: 100px;
      z-index: 1;
      margin-right: -100px;
      width: 50%;
      height: 500px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 40px;

      @media (max-width: theme("screens.xl")) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        padding: 25px;
      }

      h2 {
        font-family: $font-galanogrotesque-bold;
        font-size: 3.75rem;
        color: #9e7758;

        @media (max-width: theme("screens.lg")) {
          font-size: 2.75rem;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 15px;
        font-style: normal;

        li {
          svg {
            width: 20px;
            height: 20px;
          }
          display: flex;
          align-items: flex-start;
          gap: 10px;

          a,
          span {
            font-family: $font-galanogrotesque-normal;
            font-size: 1.5rem;
            color: $chamoisee-color;
            text-align: left;

            margin-top: -13px;

            @media (max-width: theme("screens.lg")) {
              font-size: 1.25rem;
              margin-top: -8px;
            }

            @media (max-width: theme("screens.sm")) {
              font-size: 1rem;

              margin-top: -5px;
            }
          }
        }
      }
    }
  }

  footer {
    margin-top: 65px;
    width: 100%;
    height: 77px;
    background-color: #9e7758;
  }
}
